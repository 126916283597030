import { init } from '@sentry/react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { fetchAndActivate } from 'firebase/remote-config';
import { pdfjs } from 'react-pdf';
import { remoteConfig } from 'services/firebaseConfig';

dayjs.extend(customParseFormat);

String.prototype.toTitleCase = function () {
  if (this.length) {
    return this.split(' ')
      .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
      .join(' ');
  }
  return '';
};

init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_APP_ENVIRONMENT,
});

fetchAndActivate(remoteConfig)
  .then(() => {
    return import('./main.tsx').then(() => {
      document.querySelector('.loadingspinner')?.remove();
    });
  })
  .catch((err) => console.log(err));

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();
